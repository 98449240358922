import React, {useEffect, useRef, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useResizeDetector} from 'react-resize-detector';
import Box from '@mui/material/Box';

import useDebounce from 'hooks/useDebounce';

const FeatureImportanceChart = ({data, maxHeight = 400}) => {
    const {width, ref} = useResizeDetector();
    const chartWidth = useDebounce(width, 200);

    const charOptions = () => {
        return {
            chart: {
                type: 'bar',
                height:maxHeight-10
            },
            title: {
                text: null
            },
            xAxis: {
                title: {
                    text: 'Features'
                }
            },
            yAxis: {
                title: {
                    text: 'Importance'
                }
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.2f}'
                    }
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                style: {
                    fontSize: "0.875rem",
                },
                formatter: function () {
                    return `Feature: ${this.key}, Importance: ${this.point.y.toFixed(2)}`;
                }
            },
            credits: {
                enabled: false
            }
        };
    }

    const [options, setOptions] = useState(charOptions());
    const chartRef = useRef(null);

    useEffect(() => {
            let series = []
            let features = []
            let featureImportance = []
            if (data) {
                for (const [key, value] of Object.entries(data)) {
                    features.push(key);
                    featureImportance.push({
                        name: key,
                        y: value
                    })
                }

                series = [{
                    name: 'Importance',
                    data: featureImportance
                }]
            }
            setOptions({
                ...charOptions(),
                xAxis: {
                    categories:features
                },
                series: series,
            });
        }, [data]
    )

    useEffect(() => {
        if (chartRef) {
            chartRef.current.chart.reflow();
        }

    }, [chartWidth])

    return (<Box sx={{height: '100%', minHeight: maxHeight}} ref={ref}>
            <HighchartsReact containerProps={{style: {height: "100%"}}} ref={chartRef} highcharts={Highcharts}
                             options={options}/>
        </Box>
    );
};

export default FeatureImportanceChart;
