import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import {Checkbox, FormControlLabel, FormGroup, FormHelperText, FormLabel, TextField} from '@mui/material';
import {Formik} from 'formik';
import FormControl from '@mui/material/FormControl';
import {format} from "date-fns";
import LabeledCustomSelect from "components/elements/LabeledCustomSelect";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";


const ClassifierForm = ({
                            formRef,
                            onSubmit,
                            edit = false,
                            evidence,
                            selectedVertical,
                            models,
                            selectedModel
                        }) => {

    const {t} = useTranslation();

    const [selectedTypes, setSelectedTypes] = useState([]);

    const validationSchema = Yup.object().shape({
        classification_types: Yup.array()
            .min(1, t('Select at least 1 classification type'))
            .required(t('At least 1 classification type is required')),
    });

    const handleTypeChange = (sourceId, setFieldValue) => {
        let selected = [...selectedTypes]
        if (selectedTypes.includes(sourceId)) {
            selected = selected.filter(id => id !== sourceId)
        } else {
            if (selectedTypes.length < 3) {
                selected.push(sourceId)
            }
        }

        if (!selectedTypes.includes('CLASSIC_ML_MODEL') && sourceId === 'CLASSIC_ML_MODEL') {
            setFieldValue('model_id', selectedModel)
        }

        if (selectedTypes.includes('CLASSIC_ML_MODEL') && sourceId === 'CLASSIC_ML_MODEL') {
            setFieldValue('model_id', '')
        }

        setSelectedTypes(selected)
        setFieldValue('classification_types', selected);
    }

    return (
        <Grid conainer item xs={12}>
            <Formik
                initialValues={{
                    classification_run_name: edit ? evidence['download_session_name'] : `${selectedVertical}-${format(Date.now(), 'yyyy-MM-dd HH:mm:ss')}`,
                    model_id: '',
                    classification_types: [],
                    reclassify_all_papers: false
                }}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                      handleChange,
                      values,
                      touched,
                      errors,
                      setFieldValue
                  }) => (
                    <Grid container spacing={2}>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth required
                                             error={Boolean(touched.classification_types && errors.classification_types)}>
                                    <FormLabel component="legend" sx={{fontSize: '0.875rem', fontWeight: 600}}>Classification
                                        Type</FormLabel>
                                    <FormHelperText
                                        sx={{'&.MuiFormHelperText-root': {marginLeft: 0}}}
                                        error={Boolean(touched.classification_types && errors.classification_types)}>
                                        {touched.classification_types && errors.classification_types}
                                    </FormHelperText>

                                    <FormGroup>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} container spacing={1}>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedTypes?.includes('CLASSIC_ML_MODEL')}
                                                                value={'CLASSIC_ML_MODEL'}
                                                                onChange={() => handleTypeChange('CLASSIC_ML_MODEL', setFieldValue)}
                                                                name={'CLASSIC_ML_MODEL'}/>
                                                        }
                                                        label={'Classic Machine Learning Model'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LabeledCustomSelect
                                                        label={t('Model')}
                                                        placeholder={t('Model')}
                                                        id="select-model"
                                                        name="model_id"
                                                        disabled={!selectedTypes?.includes('CLASSIC_ML_MODEL')}
                                                        InputLabelProps={{shrink: false}}
                                                        selectItems={models}
                                                        height={"calc(100vh/2)"}
                                                        onChange={handleChange}
                                                        value={values.model_id}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedTypes?.includes('OPENAI_MODEL')}
                                                                value={'OPENAI_MODEL'}
                                                                onChange={() => handleTypeChange('OPENAI_MODEL', setFieldValue)}
                                                                name={'OPENAI_MODEL'}/>
                                                        }
                                                        label={'Custom GPT Model'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField id="classification_run_name"
                                           label={t("Name")}
                                           name="classification_run_name"
                                           value={values.classification_run_name}
                                           onChange={handleChange}
                                           error={Boolean(touched.classification_run_name && errors.classification_run_name)}
                                           helperText={touched.classification_run_name && errors.classification_run_name}
                                           fullWidth
                                           variant="outlined" size="small"/>
                            </Grid>
                        </Grid>

                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default ClassifierForm;